// example theme file
const theme = {
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#D2C9B5",
    primaryDark: "#928973",
    secondary: "#B1B1B1",
    white: "#fff",
  },
  fonts: {
    body: "'D-DIN', sans-serif",
    heading: "'D-DIN', sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 24, 32, 64, 80, 128, 256, 512],
  sizes: {
    container: 1290,
  },
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  text: {
    caps: {
      textTransform: "uppercase",
    },
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    subtitle: {
      textTransform: "uppercase",
      fontWeight: "normal",
      letterSpacing: "5px",
      lineHeight: 2.5,
    },
    quote: {
      fontSize: [2, 3, 4],
      fontWeight: "normal",
      color: `primaryDark`,
      lineHeight: 1.4,
    },
    large: {
      fontSize: [4, 5, 6, 8],
    },
    medium: {
      fontSize: [3, 4, 5],
    },
  },
  forms: {
    input: {
      borderColor: "secondary",
      borderRadius: 0,
      fontFamily: "body",
      p: `10px`,
    },
    textarea: {
      borderColor: "secondary",
      borderRadius: 0,
      fontFamily: "body",
    },
    label: {
      cursor: "pointer",
    },
    select: {
      backgroundColor: "primary",
      borderColor: "primary",
      borderRadius: 0,
      color: "white",
      display: "inline-block",
      width: "auto",
      paddingX: 4,
      paddingY: 3,
      cursor: "pointer",
    },
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      backgroundColor: `background`,
      fontFamily: "body",
      fontWeight: "body",
      maxWidth: "100vw",
      overflowX: "hidden",
      a: {
        color: `primary`,
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          color: `primary`,
          textDecoration: `none`,
        },
      },
      p: {
        fontSize: [0, 1, 2],
        letterSpacing: `-0.003em`,
        lineHeight: `body`,
        "--baseline-multiplier": 0.179,
        "--x-height-multiplier": 0.35,
        color: `text`,
        marginTop: 0,
      },
      h1: {
        variant: "text.heading",
        fontSize: [4, 4, 6, 8],
        marginTop: 0,
        marginBottom: 4,
      },
      h2: {
        variant: "text.heading",
        fontSize: [4, 5, 6],
        marginTop: 0,
        marginBottom: 4,

        strong: {
          color: "primary",
        },
      },
      h3: {
        variant: "text.subtitle",
        color: "secondary",
        margin: 0,
        padding: 0,
      },
      h4: {
        variant: "text.quote",
        marginTop: 0,
        marginBottom: 3,
      },
      blockquote: {
        marginLeft: 0,
        p: {
          fontSize: [2, 3, 4],
          color: `primaryDark`,
        },
      },
    },
  },
  layout: {
    container: {
      maxWidth: "75vw",
      py: 7,
    },
  },
  buttons: {
    primary: {
      color: "background",
      bg: "primary",
      paddingX: 4,
      paddingY: 3,
      borderRadius: 0,
      cursor: "pointer",
      "&:hover": {
        bg: "primaryDark",
        color: "background",
      },
    },
    secondary: {
      bg: "transparent",
      padding: 0,
      paddingTop: 3,
      color: "primary",
      cursor: "pointer",
    },
    large: {
      fontSize: [3, 4, 5],
      backgroundColor: "transparent",
      color: "text",
      fontWeight: "bold",
      padding: 0,
      textDecoration: "underline",
      textUnderlineOffset: "6px",
      marginBottom: 4,
    },
  },
};

export default theme;
